import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { DiscussionEmbed } from 'disqus-react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import DefaultImage from '@components/DefaultImage';

const BlogDetails = data => (
  <Layout>
    <SEO
      title={data.data.contentfulBlogs.title}
      keywords={[`gatsby`, `ecommerce`, `react`, `contentFul`, `Snipcart`]}
    />
    <div className="blogs-page">
      <div className="post-thumbnail">
        {data && data.data.contentfulBlogs.featureImage ? (
          <Img sizes={data.data.contentfulBlogs.featureImage.fluid} />
        ) : (
          <DefaultImage />
        )}
      </div>
      <div className="container">
        <div className="post-details">
          <h2 className="post-title">{data.data.contentfulBlogs.title}</h2>
          <div className="post-date">
            {/* <i className="fas fa-calendar-alt" /> */}
            {data.data.contentfulBlogs.createdAt}
          </div>
          <div className="author">
            {data && data.data.contentfulBlogs.author.photo ? (
              <Img fixed={data.data.contentfulBlogs.author.photo.fixed} />
            ) : (
              <DefaultImage />
            )}
            <span className="name">
              {data.data.contentfulBlogs.author.name}
            </span>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                data.data.contentfulBlogs.description.childMarkdownRemark.html,
            }}
          />
          <div className="back">
            <Link className="primButton blog" to="/blog">
              Back to blog
            </Link>
          </div>
        </div>
        <DiscussionEmbed
          shortname={data.data.site.siteMetadata.disqusShortname}
          config={{
            identifier: data.data.contentfulBlogs.id,
            title: data.data.contentfulBlogs.title,
          }}
        />
      </div>
    </div>
  </Layout>
);

export default BlogDetails;

export const query = graphql`
  query BlogDetailsQuery($slug: String!) {
    site {
      siteMetadata {
        disqusShortname
      }
    }
    contentfulBlogs(slug: { eq: $slug }) {
      id
      title
      slug
      createdAt(formatString: "D MMMM YYYY")
      author {
        name
        photo {
          fixed(width: 50, height: 50) {
            width
            height
            src
            srcSet
          }
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      featureImage {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      categories {
        name
      }
    }
  }
`;
